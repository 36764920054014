import React, { useEffect } from 'react'
import { Button } from './Button'
import styled from 'styled-components'
import { InfoDataTwo } from '../data/InfoData';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import aboutus1 from '../images/aboutus1.jpg'



const Section = styled.section`
    width: 100%;
    height: 100%;
    padding: 4rem 0rem;
`;

const Container = styled.div`
    padding: 3rem calc((100vw - 1300px) / 2);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 800px;


    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const ColumnLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1.4;
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '2' : '1')}

    h1 {
        margin-bottom: 1rem;
        font-size: clamp(1.5rem, 6vw, 2rem);
    }

    p{
        margin-bottom: 2rem
    }
`;

const ColumnRight = styled.div`
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '1' : '2')};
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        order: ${({reverse}) => (reverse ? '2' : '1')}
    }

    img {
        width: 90%;
        height: 90%;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            width: 90%;
            height: 90%;
        }
    }
`;

const ColumnRight2 = styled.div`
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '1' : '2')};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        order: ${({reverse}) => (reverse ? '2' : '1')}
    }

    // second image on left side
    img {
        width: 90%;
        height: 90%;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            width: 90%;
            height: 90%;
        }
    }
`;

const ColumnLeft2 = styled.div`
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '1' : '2')};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
        order: ${({reverse}) => (reverse ? '2' : '1')}
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            width: 90%;
            height: 90%;
        }
    }
    h1 {
        margin-bottom: 1rem;
        font-size: clamp(1.5rem, 6vw, 2rem);
    }

    p{
        margin-bottom: 2rem
    }
`;


const AboutInfo1 = ({heading, paragraphOne, paragraphTwo, buttonLabel, reverse, image}) => {

    const controls = useAnimation()
    const { ref, inView } = useInView();
  
  
    useEffect(() => {
      if (inView) {
        controls.start('visible');
      }
      if (!inView) {
        controls.start('hidden');
      }
    }, [controls, inView]);
  


    const boxVariants = {
      hidden: { opacity: 0, translateX: -300 },
      visible: {
        opacity: 1,
        translateX: 0,
        transition: {
          duration: 1
        }
      }
    }  


 

  return (
    <div>
         <Container as={motion.div}>
             <ColumnLeft2>
                <h1><strong>About Us</strong></h1>
                <p>
                At Khoo Capital, we wish to transform neighborhoods with old buildings into more fitted modern designs in hopes of attracting up and coming new generations of the workforce.
                Our customers will be offered a luxury and a high living standard at an affordable rate. We will accomodate to our customer's needs ands issues to ensure their stay at our 
                apartments are to thier most comfort. 
                <br></br><br></br>
                We understand working everyday in the city can be chaotic, so when they return to their homes, at least we wish to give them that peace.
                The selection of each real estate is carefully planned by our team of long time native Brooklynites. From our expierence and unique views of the market to construction costs, 
                we can assure our investments are primed for growth. Investing with us is investing into the workforce of the new generation in New York City.   

                </p>
            </ColumnLeft2>

            <ColumnRight2 as={motion.div}
                ref={ref}  initial="hidden" animate={controls} variants={boxVariants}
                reverse={InfoDataTwo.reverse ? 1 : 0}
            >
                <img style={{borderRadius:'50px'}} src={aboutus1} alt='img' />
            </ColumnRight2>
        </Container>
    </div>
  )
}

export default AboutInfo1