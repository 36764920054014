import React, { useState } from 'react';
import Dropdown from './components/Dropdown';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import InfoSection from './components/InfoSection';
import InfoSection2 from './components/InfoSection2';
import { SliderData } from './data/SliderData';
import GlobalStyle from './globalStyles';
import { InfoData } from './data/InfoData';
import Footer from './components/Footer';
import { Testimonials } from './components/Testimonials';
import ProjectDetail from './components/ProjectDetail';
import Projects from './components/Projects';
import About from './components/About';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Home';
import ScrollToTop from './components/ScrollToTop';
import UnderDevelopment from './components/UnderDevelopment';
import UnderDevelopmentDetail from './components/UnderDevelopmentDetail';
import PageNotFound from './components/PageNotFound';


function App() {

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <ScrollToTop />
      <GlobalStyle />
      <Navbar toggle={toggle} />
      <Dropdown isOpen={isOpen} toggle={toggle} />
        <Routes>
          <Route path="/under-development/:projectId" element={<UnderDevelopmentDetail />} />
          <Route index element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:projectId" element={<ProjectDetail />} />
          <Route path="/about" element={<About />} />
          <Route path="/under-development" element={<UnderDevelopment />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      <Footer />
    </>
  );
}

export default App;

