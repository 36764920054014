import React, {useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import { ProjectData } from '../data/ProjectData';
import ModalImage from "react-modal-image";
import { MdOutlineApartment } from 'react-icons/md';
import { HiOutlineArrowsExpand } from 'react-icons/hi';
import { FaStore } from 'react-icons/fa';



const ProjectDetail = () => {
  const { projectId } = useParams()
  const project = ProjectData.find((project) => project.id === projectId)

  return (
    <div>
      <div className="container">
        <div className="row">
          {project.image ? <div className="project-detail-img mt-5 col-sm-8 my-2"><ModalImage small={project.image} large={project.image} /></div> : <></> }
          
          {project.image2 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image2} large={project.image2} /></div>  : <></> }
          
          {project.image3 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image3} large={project.image3} /></div>  : <></> }

          {project.image4 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image4} large={project.image4} /></div>  : <></> }

          {project.image5 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image5} large={project.image5} /></div>  : <></> }

          {project.image6 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image6} large={project.image6} /></div>  : <></> }

          {project.image7 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image7} large={project.image7} /></div>  : <></> }

          {project.image8 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image8} large={project.image8} /></div>  : <></> }

          {project.image9 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image9} large={project.image9} /></div>  : <></> }

          {project.image10 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image10} large={project.image10} /></div>  : <></> }

          {project.image11 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image11} large={project.image11} /></div>  : <></> }

          {project.image12 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image12} large={project.image12} /></div>  : <></> }
          
          {project.image13 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image13} large={project.image13} /></div>  : <></> }

          {project.image14 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image14} large={project.image14} /></div>  : <></> }

          {project.image15 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image15} large={project.image15} /></div>  : <></> }

          {project.image16 ? <div className="project-detail-img mt-5 col-sm-4 my-2"><ModalImage small={project.image16} large={project.image16} /></div>  : <></> }
          
        </div>
      

        <div className='my-5' style={{background: '#f7f7f7', padding: "2.5rem", borderRadius: '50px'}}>
          <h1>{project.title}</h1>
          {/* <h3 className='my-3'>Price: {project.price}</h3> */}
          
          <p>
            
            {project.storefront ? <><FaStore size={30} style={{marginTop:'0.8rem', marginBottom:'0.8rem', marginLeft: '2.5rem', marginRight: '0.5rem'}} />
            {project.storefront} Storefronts</>  : '' }

            {project.apartments ? <><MdOutlineApartment size={30} style={{marginTop:'0.8rem', marginBottom:'0.8rem', marginLeft: '2.5rem', marginRight: '0.5rem'}} />
            {project.apartments} Apartments</>  : '' }
            

            {project.area ? <><HiOutlineArrowsExpand size={30} style={{marginTop:'0.8rem', marginBottom:'0.8rem', marginLeft: '2.5rem', marginRight: '0.5rem'}} />
            {project.area}</>  : '' }
            
          </p>

          
            {project.description? <p> {project.description} </p> : ''}
          

          <div>

            {/* nearby schools */}
            <h3 className='mt-5'>Nearby Schools/Colleges</h3>

            <div>

              <div class="d-flex justify-content-between mt-3">
                  {project.school1? <li>{project.school1}</li> : <h5>No nearby schools or Colleges</h5>}
                  {project.distance1? <strong> {project.distance1} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.school2? <li>{project.school2}</li> : ''}
                  {project.distance2? <strong> {project.distance2} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.school3? <li>{project.school3}</li> : ''}
                  {project.distance3? <strong> {project.distance3} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.school4? <li>{project.school4}</li> : ''}
                  {project.distance4? <strong> {project.distance4} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.school5? <li>{project.school5}</li> : ''}
                  {project.distance5? <strong> {project.distance5} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.school6? <li>{project.school6}</li> : ''}
                  {project.distance6? <strong> {project.distance6} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.school7? <li>{project.school7}</li> : ''}
                  {project.distance7? <strong> {project.distance7} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.school8? <li>{project.school8}</li> : ''}
                  {project.distance8? <strong> {project.distance8} </strong> : ''}
              </div>

            </div>


            {/* nearby parks */}
            <h3>Nearby Parks</h3>

            <div>

              <div class="d-flex justify-content-between mt-3">
                  {project.park1? <li>{project.park1}</li> : <h5>No nearby Parks</h5>}
                  {project.park_dist1? <strong> {project.park_dist1} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.park2? <li>{project.park2}</li> : ''}
                  {project.park_dist2? <strong> {project.park_dist2} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.park3? <li>{project.park3}</li> : ''}
                  {project.park_dist3? <strong> {project.park_dist3} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.park4? <li>{project.park4}</li> : ''}
                  {project.park_dist4? <strong> {project.park_dist4} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.park5? <li>{project.park5}</li> : ''}
                  {project.park_dist5? <strong> {project.park_dist5} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.park6? <li>{project.park6}</li> : ''}
                  {project.park_dist6? <strong> {project.park_dist6} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.park7? <li>{project.park7}</li> : ''}
                  {project.park_dist7? <strong> {project.park_dist7} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.park8? <li>{project.park8}</li> : ''}
                  {project.park_dist8? <strong> {project.park_dist8} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.park9? <li>{project.park9}</li> : ''}
                  {project.park_dist9? <strong> {project.park_dist9} </strong> : ''}
              </div>

              <div class="d-flex justify-content-between mt-3">
                  {project.park10? <li>{project.park10}</li> : ''}
                  {project.park_dist10? <strong> {project.park_dist10} </strong> : ''}
              </div>

              

            </div>

          </div>
          <div>

          </div>

        </div>

      </div>
    </div>
  )
}

export default ProjectDetail;


