import project1 from '../images/project1.jpg'
import project1a from '../images/project1a.jpg'
import project1b from '../images/project1b.jpg'
import project1c from '../images/project1c.jpg'
import project1d from '../images/project1d.jpg'
import project1e from '../images/project1e.jpg'
import project1f from '../images/project1f.jpg'
import project1g from '../images/project1g.jpg'
import project1h from '../images/project1h.jpg'
import project1i from '../images/project1i.jpg'
import project1j from '../images/project1j.jpg'
import project1k from '../images/project1k.jpg'
import project1l from '../images/project1l.jpg'
import project1m from '../images/project1m.jpg'
import project1n from '../images/project1n.jpg'
import project1o from '../images/project1o.jpg'

import project2 from '../images/project2.jpg'
import project2a from '../images/project2a.jpg'
import project2b from '../images/project2b.jpg'
import project2c from '../images/project2c.jpg'
import project2d from '../images/project2d.jpg'
import project2e from '../images/project2e.jpg'
import project2f from '../images/project2f.jpg'
import project2g from '../images/project2g.jpg'
import project2h from '../images/project2h.jpg'
import project2i from '../images/project2i.jpg'

import project3 from '../images/project3.jpg'
import project3a from '../images/project3a.PNG'
import project3b from '../images/project3b.PNG'
import project3c from '../images/project3c.PNG'
import project3d from '../images/project3d.PNG'
import project3e from '../images/project3e.PNG'
import project3f from '../images/project3f.PNG'
import project3g from '../images/project3g.PNG'
import project3h from '../images/project3h.PNG'
import project3i from '../images/project3i.PNG'
import project3j from '../images/project3j.PNG'

import project4 from '../images/project4.jpg'
import project4a from '../images/project4a.PNG'
import project4b from '../images/project4b.PNG'
import project4c from '../images/project4c.PNG'
import project4d from '../images/project4d.PNG'
import project4e from '../images/project4e.PNG'
import project4f from '../images/project4f.PNG'

import project5 from '../images/project5.jpg'
import project5a from '../images/project5a.PNG'
import project5b from '../images/project5b.PNG'
import project5c from '../images/project5c.PNG'
import project5d from '../images/project5d.PNG'
import project5e from '../images/project5e.PNG'






export const ProjectData = [
    {
        id: '1',
        title: '1014 Fulton Street Brooklyn NY 11238 USA',
        price: '$5000',
        description: '1014 Fulton Street, Brooklyn, NY 11238, USA consists of 1 apartment with 1 bedroom, 1 bathroom and a backyard, 4 apartments with 1 bedroom and 1 bathroom, 1 pent house with an attic and 1 bathroom, this beautiful building has roof access for everyone. Building consists of 4 stories with a roof height of 44ft and dimensions of 20ft x 55ft',
        // path: '/1',
        label: 'DETAILS',
        image: project1,
        image2: project1a,
        image3: project1b,
        image4: project1c,
        image5: project1d,
        image6: project1e,
        image7: project1f,
        image8: project1g,
        image9: project1h,
        image10: project1i,
        image11: project1j,
        image12: project1k,
        image13: project1l,
        image14: project1m,
        image15: project1n,
        image16: project1o, 

        school1: 'Pratt Institute', 
        distance1: '0.67 miles',

        school2: "ST.Joseph's College", 
        distance2: '0.71 miles',

        school3: 'Medgar Evers College (CUNY)', 
        distance3: '1.07 miles', 

        school4: 'Long Island University-Brooklyn Campus (CUNY)', 
        distance4: '1.27 miles', 

        school5: 'Long Island University', 
        distance5: '1.27 miles', 


        park1: 'Park', 
        park_dist1: '0.09 miles',

        park2: "Lefferts Pl Block Association", 
        park_dist2: '0.17 miles',

        park3: 'Madison Street Block Association', 
        park_dist3: '0.29 miles', 

        park4: 'Cedar Tree Garden', 
        park_dist4: '0.41 miles', 

        park5: 'Walt L Shemal Garden', 
        park_dist5: '0.46 miles', 

        apartments: '6',
        storefront: '1',
        alt: 'project',
        beds: '4',
        baths: '4',
        area: '5521 sqf',
    }, 


    {
        id: '2',
        title: '685 5th Ave New York NY 10022 USA',
        price: '$8000',
        description: '685 5th Ave, New York, NY 10022, USA consists of 1 pent house with 2 bedrooms, 1bathroom, an attic and a personal roof , 1 studio with 0 bedrooms, 1 bathroom and 1 storefront, this beautiful building also has roof access for everyone. The high quality of the finishing work can be seen throughout the home. Building consists of 3 stories with a roof height of 31ft and dimensions of 20ft x 40ft',
        // path: '/under-development',
        label: 'DETAILS',
        image: project2,
        image2: project2a,
        image3: project2b,
        image4: project2c,
        image5: project2d,
        image7: project2e,
        image8: project2f,
        image9: project2g,
        image10: project2h,
        image11: project2i,


        school1: 'P.S 295 (PK to 06, with SE)', 

        school2: 'Medgar Evers College (CUNY)',
        distance2: '1.89 miles',


        park1: 'Detective Joseph Mayrose Park', 
        park_dist1: '0.27 miles',

        park2: "Butterfly Gardens", 
        park_dist2: '0.35 miles',

        park3: 'Park', 
        park_dist3: '0.42 miles', 

        park4: 'Bartel-Pritchard Square', 
        park_dist4: '0.69 miles', 

        park5: 'Prospect Park', 
        park_dist5: '0.71 miles', 


        apartments: '6',
        storefront: '1',
        alt: 'project',
        beds: '1',
        baths: '1',
        area: '2400 sqf',
    },

    {
        id: '3',
        title: '126 Winthrop Street Brooklyn NY 11225 USA',
        price: '$8000',
        // description: '685 5th Ave, New York, NY 10022, USA consists of 1 pent house with 2 bedrooms, 1bathroom, an attic and a personal roof , 1 studio with 0 bedrooms, 1 bathroom and 1 storefront, this beautiful building also has roof access for everyone. The high quality of the finishing work can be seen throughout the home. Building consists of 3 stories with a roof height of 31ft and dimensions of 20ft x 40ft',
        // path: '/under-development',
        label: 'DETAILS',
        image: project3,
        image2: project3a,
        image3: project3b,
        image4: project3c,
        image5: project3d,
        image6: project3e,
        image7: project3f,
        image8: project3g,
        image9: project3h, 
        image10: project3i,
        image11: project3j,
        


        school1: 'Parkside Preparatory Academy (06 to 08 with SE)', 

        school2: 'P.S 092 Adrian Hegeman (PK to 05 with SE)',

        school3: 'SUNY Downstate Medical Center',
        distance3: '0.54 miles',

        school3: 'Medgar Evers College (CUNY)',
        distance3: '0.7 miles',

        school3: 'Brooklyn College (CUNY)',
        distance3: '1.81 miles',


        park1: 'Prospect Park', 
        park_dist1: '0.36 miles',


        park2: 'Lincoln Rd Clock Association', 
        park_dist2: '0.38 miles',


        park3: "Red Hook Recreation Area", 
        park_dist3: '0.58 miles',

        park4: 'Hamilton Metz Field', 
        park_dist4: '0.88 miles', 

        park5: 'Machate Circle', 
        park_dist5: '0.94 miles', 

        

        apartments: '4',
        storefront: '4',
        alt: 'project',
        beds: '1',
        baths: '1',
        area: '5000 sqf',

        // apartments: '6',
        // storefront: '1',
        // alt: 'project',
        // beds: '1',
        // baths: '1',
        // area: '2400 sqf',
    },

    {
        id: '4',
        title: '958 71st Street Brooklyn NY 11228 USA',
        price: '$8000',
        // description: '685 5th Ave, New York, NY 10022, USA consists of 1 pent house with 2 bedrooms, 1bathroom, an attic and a personal roof , 1 studio with 0 bedrooms, 1 bathroom and 1 storefront, this beautiful building also has roof access for everyone. The high quality of the finishing work can be seen throughout the home. Building consists of 3 stories with a roof height of 31ft and dimensions of 20ft x 40ft',
        // path: '/under-development',
        label: 'DETAILS',
        image: project4,
        image2: project4a,
        image3: project4b,
        image4: project4c,
        image5: project4d,
        image6: project4e,
        image7: project4f,
        


        school1: 'J.H.S 259 William McKinley (06 to 08 with SE)', 

        school2: 'Ralph a. Fabrizio School (0K to 05 with SE)',


        park1: 'McKinley Park', 
        park_dist1: '0.15 miles',


        park2: 'Leif Ericson Park', 
        park_dist2: '0.24 miles',


        park3: "Tom McDonald Triangle", 
        park_dist3: '0.63 miles',

        park4: 'Dyker Beach Park', 
        park_dist4: '0.77 miles', 

        park5: 'Shore Park and Parkway', 
        park_dist5: '0.9 miles', 

        

        apartments: '2',
        storefront: '',
        alt: 'project',
        beds: '1',
        baths: '1',
        area: '3120 sqf',
    },

    {
        id: '5',
        title: '5718 3rd Avenue Brooklyn NY 11220 USA',
        price: '$8000',
        // description: '685 5th Ave, New York, NY 10022, USA consists of 1 pent house with 2 bedrooms, 1bathroom, an attic and a personal roof , 1 studio with 0 bedrooms, 1 bathroom and 1 storefront, this beautiful building also has roof access for everyone. The high quality of the finishing work can be seen throughout the home. Building consists of 3 stories with a roof height of 31ft and dimensions of 20ft x 40ft',
        // path: '/under-development',
        label: 'DETAILS',

        image: project5,
        image3: project5a,
        image3: project5b,
        image4: project5c,
        image5: project5d,
        image6: project5e,
        


        school1: 'J.H.S 220 John J. Pershing (06 to 08 with SE)', 

        school2: 'P.S 503: the School Oof Discovery (0K to 05 with SE)',

        

        // parks
        park1: 'Shore Park and Parkway', 
        park_dist1: '0.44 miles',


        park2: 'Leif Ericson Park', 
        park_dist2: '0.49 miles',


        park3: "Owl's Head park", 
        park_dist3: '0.55 miles',

        park4: 'Sunset Park', 
        park_dist4: '0.75 miles', 

        park5: 'McKinley Park', 
        park_dist5: '1.09 miles', 

    
        apartments: '8',
        storefront: '1',
        alt: 'project',
        beds: '1',
        baths: '1',
        area: '7272 sqf',
    },
     
]

