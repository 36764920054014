import React, { useState } from 'react';
import Dropdown from './components/Dropdown';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import InfoSection from './components/InfoSection';
import InfoSection2 from './components/InfoSection2';
import { SliderData } from './data/SliderData';
import GlobalStyle from './globalStyles';
import { InfoData } from './data/InfoData';
import Footer from './components/Footer';
import { Testimonials } from './components/Testimonials';
import Projects from './components/Projects';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";



function Home() {

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Hero slides={SliderData}/>
      <InfoSection {...InfoData} />
      <InfoSection2 {...InfoData} />
      {/* <Testimonials /> */}
    </>
  );
}

export default Home;