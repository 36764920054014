import React, {useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import { UnderDevelopmentData } from '../data/UnderDevelopmentData';
import ModalImage from "react-modal-image";
import { FaBath, FaBed } from 'react-icons/fa';
import { HiOutlineArrowsExpand } from 'react-icons/hi';


const UnderDevelopmentDetail = () => {
  const { projectId } = useParams()
  const project = UnderDevelopmentData.find((project) => project.id === projectId)

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="row mt-5">
              {project.image ? <div className="project-detail-img col-sm-6 my-2"><ModalImage small={project.image} large={project.image} /></div> : <></> }
              
              {project.image2 ? <div className="project-detail-img col-sm-6 my-2"><ModalImage small={project.image2} large={project.image2} /></div>  : <></> }
              
              {project.image3 ? <div className="project-detail-img col-sm-4 my-2"><ModalImage small={project.image3} large={project.image3} /></div>  : <></> }

              {project.image4 ? <div className="project-detail-img col-sm-4 my-2"><ModalImage small={project.image4} large={project.image4} /></div>  : <></> }

              {project.image5 ? <div className="project-detail-img col-sm-4 my-2"><ModalImage small={project.image5} large={project.image5} /></div>  : <></> }

              {project.image6 ? <div className="project-detail-img col-sm-4 my-2"><ModalImage small={project.image6} large={project.image6} /></div>  : <></> }

              {project.image7 ? <div className="project-detail-img col-sm-4 my-2"><ModalImage small={project.image7} large={project.image7} /></div>  : <></> }
              
            </div>
        </div>
      

        <div className='my-5' style={{background: '#f7f7f7', padding: "2.5rem", borderRadius: '50px'}}>
          <h1>{project.title}</h1>
          {/* <h1>Price: {project.price}</h1> */}
          {/* <p>
            <FaBed size={35} style={{marginTop:'0.8rem', marginBottom:'0.8rem', marginLeft: '2.5rem', marginRight: '0.5rem'}} />
            {project.beds} Beds
            
            <FaBath size={30} style={{marginTop:'0.8rem', marginBottom:'0.8rem', marginLeft: '2.5rem', marginRight: '0.5rem'}} />
            {project.baths} Baths

            <HiOutlineArrowsExpand size={30} style={{marginTop:'0.8rem', marginBottom:'0.8rem', marginLeft: '2.5rem', marginRight: '0.5rem'}} />
            {project.area}
          </p> */}

          <p>
            {project.description}
          </p>

        </div>


      </div>
    </div>
  )
}

export default UnderDevelopmentDetail;


