import React, {useEffect} from 'react'
import styled, { css } from 'styled-components/macro'
import { Link, NavLink, useLocation  } from 'react-router-dom'
import { Button } from './Button';
import { RiMenu3Line } from 'react-icons/ri'
import navbar from './navbar.css'
import mkhoo from '../images/mkhoo.svg'



const Nav = styled.nav`
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 100;
    position: fixed;
    width: 100%;
`;

const NavbarLinks = css`
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
`

const Logo = styled(Link)`
    ${NavbarLinks}
    padding: 0 0;

    @media screen and (max-width: 400px) {
        height: 2rem, 
        width: auto
    }
`

const MenuBars = styled(RiMenu3Line)`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        height: 40px;
        width: 40px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-50%, 25%);
        color: white;
    }
`

const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: 48px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const NavMenuLinks = styled(Link)`
    ${NavbarLinks}
`

const NavBtn = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`




const Navbar = ({toggle}) => {


 


    useEffect(() => {
        document.addEventListener("scroll", () => {
            let header = document.querySelector('.Header');
            if (window.scrollY > 10) {
                header?.classList.add('scrolled');
                }else {
                header?.classList.remove('scrolled');
                }
        })
    }, [])


    const location = useLocation();
    

  return (
    <Nav className={location.pathname === '/' ? 'Header' : 'Header'}>
          <Logo to="/"><img style={{height:'2rem', width:'auto' }} src={mkhoo} /></Logo> 
        <MenuBars onClick={toggle} />

        <NavMenu>
            <NavMenuLinks to="/projects">Completed Projects</NavMenuLinks>
            <NavMenuLinks to="/under-development">Under Development</NavMenuLinks>
            <NavMenuLinks to="/about">About Us</NavMenuLinks>
        </NavMenu>
        <NavBtn>
            <a className='go-footer' href="#go-footer">Contact Us</a>
        </NavBtn>
    </Nav>
  )
}

export default Navbar