import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { ProjectData } from '../data/ProjectData';
import { Button } from './Button';
import { IoMdArrowRoundForward } from 'react-icons/io'
import { IoArrowForward, IoArrowBack } from 'react-icons/io5'
import { FaBed, FaBath } from 'react-icons/fa'
import { HiOutlineArrowsExpand } from 'react-icons/hi'
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';



const HeroSection = styled.section`
    height: 100vh;
    max-height: 1100px;
    position: relative;
    overflow: hidden;
`;

const HeroWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
`;

const HeroSlide = styled.div`
    z-index: 1;
    width: 100%;
    height: 100%;
`;

const HeroSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 0.4;
    background: linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.6) 100%)
  }
`;

const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: calc(100% - 100px);
  color: #fff;

  h1 {
    font-size: clamp(1rem, 8vw, 2rem);
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align: left;
    margin-bottom: 0.8rem;
  }

  p {
    margin-bottom: 1.2rem;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  }
`;

const Arrow = styled(IoMdArrowRoundForward)`
   margin-left: 0.5rem;
   font-size: 18px;
`;

const SliderButtons = styled.div`
   position: absolute;
   bottom: 50px;
   right: 50px;
   display: flex;
   z-index: 10;
`;

const arrowButtons = css`
  width: 50px;
  height: 50px;
  color: #fff;
  cursor: pointer;
  background: #000d1a;
  border-radius: 50px;
  padding 10px;
  margin-right: 1rem;
  user-select: none;
  transition: 0.3s;

  &:hover {
    background: #cd853f;
    transform: scale(1.05);
  }
`;

const PrevArrow = styled(IoArrowBack)`
   ${arrowButtons}
`;

const NextArrow = styled(IoArrowForward)`
  ${arrowButtons}
`;

const Projects = () => {

  const [current, setCurrent] = useState(0)
  const length = ProjectData.length
  const timeout = useRef(null)

  useEffect(() => {
    const nextSlide = () =>{
      setCurrent(current => (current === length - 1 ? 0 : current + 1))
    }

    timeout.current = setTimeout(nextSlide, 5000)

    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [current, length])

  const nextSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    setCurrent(current === length - 1 ? 0 : current + 1)
  }


  const prevSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  if(!Array.isArray(ProjectData) || ProjectData.length <= 0) {
    return null
  }

  // if(current > length || current < 0) {
  //   setCurrent(0)
  // }

  return (
    <div>
        <HeroSection  as={motion.div} initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }} 
        >
            <HeroWrapper>
                {ProjectData.map((slide, index) => {
                return (
                    <HeroSlide key={index}>
                    {index === current && (
                        <HeroSlider>
                        <HeroImage src={slide.image} at={slide.alt} />
                        <HeroContent>
                        <h1>{slide.title}</h1>
                        {/* <h5>{slide.price}</h5> */}

                        {/* <div>
                    
                            <h5>
                            <FaBed size={35} style={{marginTop:'0.8rem', marginBottom:'0.8rem', marginRight: '2rem'}} />
                            {slide.beds} Beds
                            </h5>
                            
                            <h5>
                            <FaBath size={30} style={{marginTop:'0.8rem', marginBottom:'0.8rem', marginRight: '2rem'}} />
                            {slide.baths} Baths
                            </h5>

                            <h5>
                            <HiOutlineArrowsExpand size={30} style={{marginTop:'0.8rem', marginBottom:'0.8rem', marginRight: '2rem'}} />
                            {slide.area}
                            </h5>

                        </div> */}
                        
                        <Button style={{borderRadius: '200px'}} to={slide.id} primary2="true" css={`max-width: 160px`}>
                            {slide.label}
                            <Arrow />
                        </Button>
                        </HeroContent>
                    </HeroSlider>
                    )}
                    </HeroSlide>
                )
                })}

                <SliderButtons>
                <PrevArrow onClick={prevSlide} />
                <NextArrow onClick={nextSlide} />
                </SliderButtons>
            </HeroWrapper>
        </HeroSection>


        <h1 className='text-center' style={{marginTop:'6rem'}}>Available Properties</h1>
            <div className="row row-cols-1 row-cols-md-2 g-4" style={{marginTop:'2rem'}}>
              {ProjectData.map((slide, index) => {
                return (
                      <div  key={index} className=' col col-lg-4 d-flex align-items-stretch'>
                        <Link className='projects-link' style={{textDecoration:'none'}} to={`/projects/${slide.id}`} >
                        
                          <motion.div 
                              initial={{ translateX: -200, opacity: 0 }}
                              whileInView={{ translateX: 0, opacity: 1 }}
                              transition={{ duration: 0.3 }}
                              style={{cursor: 'pointer'}}
                              whileHover={{
                                  scale: 1.1,
                                  transition: { duration: 0.2 },
                                }}
                                whileTap={{ scale: 0.9 }}
                                className="border-0 main-card card mt-5 mx-5 border"
                          >
                              <img style={{height: '20rem', objectFit:'contain'}} className="card-img-top" src={slide.image} alt="Card image cap" />
                              <div className="card-body">
                                  <h5 className="card-title"><b>{slide.title}</b></h5>
                                  <p className="card-text my-4">This property is best for a family of 5 people, the house has a swimming pool and a really nice view.</p>
                              </div>
                              {/* <div style={{backgroundColor:'white'}} className="card-footer">
                                  <b className="text-muted">Price: $5000</b>
                              </div> */}
                          </motion.div>
                        </Link>
                      </div>
                  )
                })}
            </div>



        </div>
  )
}

export default Projects