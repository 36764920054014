import React, { useEffect } from 'react'
import { Button } from './Button'
import styled from 'styled-components'
import { InfoDataTwo } from '../data/InfoData';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import aboutus2 from '../images/aboutus2.jpg'



const Section = styled.section`
    width: 100%;
    height: 100%;
    padding: 4rem 0rem;
`;

const Container = styled.div`
    padding: 3rem calc((100vw - 1300px) / 2);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 800px;


    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const ColumnLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1.4;
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '2' : '1')}

    h1 {
        margin-bottom: 1rem;
        font-size: clamp(1.5rem, 6vw, 2rem);
    }

    p{
        margin-bottom: 2rem
    }
`;

const ColumnRight = styled.div`
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '1' : '2')};
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        order: ${({reverse}) => (reverse ? '2' : '1')}
    }

    img {
        width: 90%;
        height: 90%;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            width: 90%;
            height: 90%;
        }
    }
`;

const ColumnRight2 = styled.div`
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '1' : '2')};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        order: ${({reverse}) => (reverse ? '2' : '1')}
    }

    // second image on left side
    img {
        width: 90%;
        height: 90%;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            width: 90%;
            height: 90%;
        }
    }
`;

const ColumnLeft2 = styled.div`
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '1' : '2')};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
        order: ${({reverse}) => (reverse ? '2' : '1')}
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            width: 90%;
            height: 90%;
        }
    }
    h1 {
        margin-bottom: 1rem;
        font-size: clamp(1.5rem, 6vw, 2rem);
    }

    p{
        margin-bottom: 2rem
    }
`;


const AboutInfo2 = ({heading, paragraphOne, paragraphTwo, buttonLabel, reverse, image}) => {

    const controls = useAnimation()
    const { ref, inView } = useInView();
  
  
    useEffect(() => {
      if (inView) {
        controls.start('visible');
      }
      if (!inView) {
        controls.start('hidden');
      }
    }, [controls, inView]);
  


    const boxVariants = {
      hidden: { opacity: 0, translateX: -300 },
      visible: {
        opacity: 1,
        translateX: 0,
        transition: {
          duration: 1
        }
      }
    }  


    reverse=false

  return (
    <div>
         <Container as={motion.div}>
             <ColumnLeft2>
                <h1><strong>We Care</strong></h1>
                <li>We are customer focused.</li>
                <li>We bring a positive energy and attitude to everything you do.</li>
                <li>We work hard, work smart, and always get the job done.</li>
                <li>We are team players.</li>
                <li>We turn our client’s real estate goals into reality.</li>
            </ColumnLeft2>
            <ColumnRight2 as={motion.div}
                ref={ref}  initial="hidden" animate={controls} variants={boxVariants}
                reverse={reverse ? 1 : 0}
            >
                <img  style={{borderRadius:'50px'}} src={aboutus2} alt='img' />
            </ColumnRight2>
        </Container>
    </div>
  )
}

export default AboutInfo2
