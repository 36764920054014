import React from 'react'
import { MdConstruction } from 'react-icons/md'
import AboutImg from '../images/AboutImg.jpg'
import styled from 'styled-components'
import AboutInfo1 from './AboutInfo1'
import AboutInfo2 from './AboutInfo2'
// import team1 from '../images/team1.jpg'
// import team2 from '../images/team2.jpg'
// import team3 from '../images/team3.jpg'
// import team4 from '../images/team4.jpg'
// import team5 from '../images/team5.jpg'




const Contact = () => {

  return (
    <div>
        <div>
          <img className='card-img-top' src={AboutImg} style={{width: '100%', height:'100vh', objectFit:'cover'}} />
          <div className="row carousel-caption mx-md-n5">
   
            <div className="col px-md-5">
              <div className="p-3">
                <h1><strong>10+</strong></h1>
                <h5>Years Of Experience In Construction And Management Buisness</h5>
              </div>
            </div>

            {/* <div className="col px-md-5">
              <div className="p-3">
                <h1><strong>200+</strong></h1>
                <h5>Happy Customers</h5>
              </div>
            </div> */}

            {/* <div className="col px-md-5">
              <div className="p-3">
                <h1><strong>4+</strong></h1>
                <h5>Awards Gained</h5>
              </div>
            </div> */}

          </div>
        </div>


        <div>
          <AboutInfo1 />
          {/* <AboutInfo2 /> */}
        </div>


        {/* <h1 style={{marginTop:'150px', fontSize:'3rem', marginBottom:'5rem'}} className="text-center text-decoration-underline">Meet Our Team</h1> */}
        
        {/* <div className="d-flex flex-wrap justify-content-center g-4" style={{marginTop:'2rem', marginRight:'5rem', marginLeft:'5rem'}}>
          
            <div className=' col col-lg-4 d-flex align-items-stretch d-flex justify-content-center'>
                <div className="mt-5 mx-5 ">
                <img style={{width:'15rem', height:'15rem'}} src={team1} className='rounded-circle' />
                  <div className="card-body text-center">
                      <h5 className="card-title mt-3"><b>Julian English</b></h5>
                      <p className="card-text my-4">C.E.O</p>
                  </div>
                  
                </div>
            </div>


            <div className=' col col-lg-4 d-flex align-items-stretch d-flex justify-content-center'>
                <div className="mt-5 mx-5 ">
                <img style={{width:'15rem', height:'15rem'}} src={team5} className='rounded-circle' />
                  <div className="card-body text-center">
                      <h5 className="card-title mt-3"><b>Ian Browns</b></h5>
                      <p className="card-text my-4">C.T.O</p>
                  </div>
                  
                </div>
            </div>
        

          
            <div className=' col col-lg-4 d-flex align-items-stretch d-flex justify-content-center'>
                <div className="mt-5 mx-5 ">
                <img style={{width:'15rem', height:'15rem'}} src={team2} className='rounded-circle' />
                  <div className="card-body text-center">
                      <h5 className="card-title mt-3"><b>Roger Jackson</b></h5>
                      <p className="card-text my-4">Designer</p>
                  </div>
                  
                </div>
            </div>
        

          
            <div className=' col col-lg-4 d-flex align-items-stretch d-flex justify-content-center'>
                <div className="mt-5 mx-5 ">
                <img style={{width:'15rem', height:'15rem'}} src={team3} className='rounded-circle' />
                  <div className="card-body text-center">
                      <h5 className="card-title mt-3"><b>Jack John</b></h5>
                      <p className="card-text my-4">Junior Developer</p>
                  </div>
                  
                </div>
            </div>
        

          
            <div className=' col col-lg-4 d-flex align-items-stretch d-flex justify-content-center'>
                <div className="mt-5 mx-5 ">
                <img style={{width:'15rem', height:'15rem'}} src={team4} className='rounded-circle' />
                  <div className="card-body text-center">
                      <h5 className="card-title mt-3"><b>John Smith</b></h5>
                      <p className="card-text my-4">Senior Developer</p>
                  </div>
                  
                </div>
            </div>

        </div> */}

                 
    </div>
  )
}
                        

export default Contact

