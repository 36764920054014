import ImageFirst from '../images/ImageFirst.jpg'
import ImageSecond from '../images/ImageSecond.jpg'


export const InfoData = {
    heading: 'Explore our beautiful homes',
    paragraphOne: 'WE INTEND TO PROVIDE AN EVER-GROWING CATALOG OF LUXURY REAL ESTATE LISTINGS IN SOME OF THE MOST SOUGHT AFTER DESTINATIONS IN THE WORLD.WE CONNECT THE FINEST REAL ESTATE TO THE MOST PRESTIGIOUS CLIENTELE IN THE WORLD. ',
    paragraphTwo: 'OUR PORTFOLIO WILL INCLUDE ALL TYPES OF LUXURIOUS PROPERTIES FROM CUSTOM-BUILT SINGLE FAMILY HOMES, CONDOMINIUMS, PENTHOUSES, VILLAS AND MORE. WE WILL MATCH THE AMENITIES AND LIFESTYLE CHOICES OF OUR NICHE CLIENTELE WITH FEATURES SUCH AS WATERFRONT VIEWS, GOLF COURSE COMMUNITIES, PRIVATE ISLANDS, VACATION PROPERTIES, MOUNTAIN RESORT PROPERTIES, AND MANY OTHERS.',
    buttonLabel: 'View Homes',
    image: ImageFirst,
    reverse: false,
    delay: 100,
}

export const InfoDataTwo = {
    heading: 'Why choose us',
    paragraphOne: 'WE HAVE AN EXCELLENT REPUTATION IN THE REAL ESTATE MARKET AND IN THE COMMUNITIES WE SERVE. WE ARE ACTIVE, INVOLVED AND CONNECTED AT THE HIGHEST LEVELS. WE OPERATE LOCALLY AS WELL AS INTERNATIONALLY.',
    paragraphTwo: 'OUR TEAM LIVES IN THE COMMUNITIES WE SERVE, ALLOWING US TO BE ON THE PULSE OF THE MARKET AND MAKING TRANSACTIONS HAPPEN. OUR CORPORATE INVOLVEMENT WITH NUMEROUS ORGANIZATIONS AND GOVERNMENT HELP US BECOME INVOLVED IN MORE PROJECTS, NEGOTIATE WITH GREATER LEVERAGE, AND SIMPLY PUT, PROVIDE OUR CLIENTS AN ADVANTAGE.',
    buttonLabel: 'View Homes',
    image: ImageSecond,
    reverse: true,
    delay: 300,
}