import under_dev1 from '../images/under_dev1.jpg'
import under_dev2 from '../images/under_dev2.png'


export const UnderDevelopmentData = [

    {
        id: '1',
        title: '150 2nd Avenue Brooklyn NY 10003 USA',
        description: 'This will an e-commerce warehouse with 9000 sqft',
        label: 'DETAILS',
        image: under_dev1,
        alt: 'project',
        // price: '$5000',
        // path: '/1',
        // beds: '4',
        // baths: '4',
        // area: '8sqf',
    },    
    

    {
        id: '2',
        title: '595 3rd Avenue Brooklyn NY 11215 USA',
        description: 'This will be an 8 apartment building.',
        label: 'DETAILS',
        image: under_dev2,
        alt: 'project',
        // price: '$5000',
        // path: '/1',
        // beds: '4',
        // baths: '4',
        // area: '8sqf',
    },    
    
]