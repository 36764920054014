import ImageOne from '../images/ImageOne.jpg'
import ImageTwo from '../images/ImageTwo.jpg'
import ImageThree from '../images/ImageThree.jpg'
import ImageFour from '../images/ImageFour.jpg'



export const SliderData = [
    {
        title: 'Luxury apartments',
        description: 'Take a look at our world class apartments.',
        path: '/projects',
        label: 'VIEW',
        image: ImageOne,
        alt: 'project'
    },
    {
        title: 'Under development',
        description: 'Want to take a look at what we are working on?',
        path: '/under-development',
        label: 'VIEW',
        image: ImageTwo,
        alt: 'project'
    },
    {
        title: 'Have a question?',
        description: 'Want to reach out to us? Feel free to contact us.',
        path: '/#go-footer',
        label: '',
        image: ImageThree,
        alt: 'project'
    },
    {
        title: 'Who are we?',
        description: 'Want to know more about us and about what we do?',
        path: '/about',
        label: 'ABOUT US',
        image: ImageFour,
        alt: 'project'
    },
]