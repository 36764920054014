import styled from 'styled-components'
import { Link } from 'react-router-dom'
    

export const Button = styled(Link)`
    border-radius: 200px;
    background: ${({ primary2 }) => (primary2 ? '#000d1a' : 'cd853f')};
    white-space: nowrap;
    outline: none;
    border: none;
    min-width: 100px;
    max-width: 200px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({big}) => (big ? '6px 30px' : '14px 24px')};
    color: ${({primary2}) => (primary2 ? '#fff' : '#000d1a')};
    font-size:  ${({big}) => (big ? '20px' : '14px')};

    &:hover {
        transform: translateY(-3px);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
    }
`