import React from 'react'

const PageNotFound = () => {
  return (
    <div style={{width:'100%', height:'100vh'}}>
        <h1 className='text-center' style={{display:'block', top:'50%', position: 'relative'}}>
            404 PAGE NOT FOUND
        </h1>
    </div>
  )
}

export default PageNotFound