import React, {useEffect} from 'react'
import footer from './footer.css'
import { AiFillInstagram, AiFillTwitterCircle } from 'react-icons/ai';
import { BsFacebook } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import kahoo_footer from '../images/kahoo_footer.svg'







const Footer = () => {

  const controls = useAnimation()
  const { ref, inView } = useInView();


  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
    if (!inView) {
      controls.start('hidden');
    }
  }, [controls, inView]);

  // const boxVariants = {
  //   hidden: { scale: 0 },
  //   visible: {
  //     scale: 1,
  //     transition: {
  //       duration: 0.5
  //     }
  //   }
  // }

  const boxVariants = {
    hidden: { opacity: 0, translateX: -200 },
    visible: {
      opacity: 1,
      translateX: 0,
      transition: {
        duration: .5
      }
    }
  }
  

  
  

  return (
    <footer className='go-footer' id='go-footer' style={{marginTop:'5rem'}}>
        <div className="row primary2">
          <div className="column about justify-content-center">
            {/* -------------------------------------------- */}
            {/* -------------------------------------------- */}
            <motion.h1 ref={ref} className="Box" initial="hidden" animate={controls} variants={boxVariants} style={{fontSize:'70px', color:'#fff', textAlign: 'center', marginBottom:'2rem'}}>Let's find your dream home!</motion.h1>
          </div>

        <div className="column links" style={{marginTop:'0.5rem', textAlign: 'center', color:'#fff', marginBottom:'2rem'}}>
          <h3 style={{textAlign: 'center', textDecoration:'underline'}}>Quick Links</h3>

          <ul className="container-fluid">
            <li style={{marginTop:'1rem'}}> 
            <a  style={{fontSize: '15px', color:'#fff'}} href="/projects">Completed projects</a>
            </li>
            <li style={{marginTop:'1.7rem'}}>
            <a style={{fontSize: '15px', color:'#fff'}} href="/under-development">Under Development</a>
            </li>
            <li style={{marginTop:'1.7rem'}}>
            <a style={{fontSize: '15px', color:'#fff'}} href="/about">About Us</a>
            </li>
            {/* <li style={{marginTop:'1.7rem'}}>
            <a style={{fontSize: '15px', color:'#fff'}} href="#support">Support</a>
            </li> */}
          </ul>

        </div>


        <div className="column links container-fluid" style={{marginTop:'0.5rem', textAlign: 'center', color:'#fff'}}>
          <h3  style={{textAlign: 'center', textDecoration:'underline'}}>Our Socials</h3>
          <ul className="row justify-content-center">

            <li>
              <a href='https://www.instagram.com/khoo_capital/'>
                <AiFillInstagram  
                    style={{cursor: 'pointer'}}
                    onMouseOver={({target})=>target.style.color="#cd853f"}
                    onMouseOut={({target})=>target.style.color="white"}
                    size={25} 
                />
              </a>
            </li>

            {/* <li>
            <BsFacebook
                style={{cursor: 'pointer'}}
                onMouseOver={({target})=>target.style.color="#cd853f"}
                onMouseOut={({target})=>target.style.color="white"} 
                size={25} 
            />
            </li> */}

            <li>
              <a href='https://www.twitter.com/KhooCapital/'>
                <AiFillTwitterCircle 
                    style={{cursor: 'pointer'}}
                    onMouseOver={({target})=>target.style.color="#cd853f"}
                    onMouseOut={({target})=>target.style.color="white"}
                    size={30} 
                />
              </a>
            </li>

            <li>
            <a href='mailto: khoocapital@gmail.com'>
              <MdEmail 
                  style={{cursor: 'pointer'}}
                  onMouseOver={({target})=>target.style.color="#cd853f"}
                  onMouseOut={({target})=>target.style.color="white"} 
                  size={25} 
              />
            </a>
            </li>

            <li>
              <p>khoocapital@gmail.com</p>
            </li>

          </ul>
        </div>



        {/* <div className="column links" style={{marginTop:'0.5rem', textAlign: 'center', color:'#fff'}}>
          <h3 className='text-center' style={{textAlign: 'center', textDecoration:'underline'}}>Address</h3>
          <ul className="row justify-content-center">
            <li  style={{marginTop:'1rem', fontSize:'15px'}}>
                4259 Heliport Loop
            </li>
            <li  style={{marginTop:'1rem', fontSize:'15px'}}>
                New Harmony
            </li>
            <li  style={{marginTop:'1rem', fontSize:'15px'}}>
                Indiana, USA
            </li>
            <li  style={{marginTop:'1rem', fontSize:'15px'}}>
                zipcode: 47631
            </li>
            
          </ul>
        </div> */}


        <div className='d-flex justify-content-center align-items-center'>
          <img style={{height:'12rem', width:'auto' }} src={kahoo_footer} />
        </div> 
        
        </div>

    </footer>

  )
}

export default Footer
